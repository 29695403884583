<template>
  <div class="market_create">
    <div class="creative_nav">
      <router-link to="/create/group">

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3148_4721)">
            <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_3148_4721">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </router-link>
      <span>
          Ishtirokchini qidirish</span>
    </div>
    <div class="form_main">
      <form @submit.prevent="">
        <div class="create_group">

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3271_765)">
              <path d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z" stroke="#191923" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2 14V12.6667C2 11.9594 2.28095 11.2811 2.78105 10.781C3.28115 10.281 3.95942 10 4.66667 10H7.33333C8.04058 10 8.71885 10.281 9.21895 10.781C9.71905 11.2811 10 11.9594 10 12.6667V14" stroke="#191923" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.667 2.08667C11.2406 2.23354 11.749 2.56714 12.1121 3.03488C12.4752 3.50262 12.6722 4.07789 12.6722 4.67C12.6722 5.26212 12.4752 5.83739 12.1121 6.30513C11.749 6.77287 11.2406 7.10647 10.667 7.25334" stroke="#2C3E50" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M14 14.0001V12.6668C13.9966 12.0782 13.7986 11.5073 13.4368 11.043C13.0751 10.5788 12.5699 10.2472 12 10.1001" stroke="#2C3E50" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3271_765">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <input type="text" placeholder="Ishtirokchini qidirish" v-model="group_name">
        </div>
        <div class="create_button g_button">
          <button :class="{'active':isSpinner}">
            <span v-if="!isSpinner">qo’shish</span>
            <b-spinner variant="white" v-if="isSpinner"></b-spinner>
          </button>
        </div>
      </form>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>

import Footer from "../../components/Header/Footer";
export default {
  components:{
    Footer,
  },
  data(){
    return{
      phone_number:'',
      address:'',
      name:'',
      isVisible:3,
      imageUrl:[],
      myImages:[],
      files_name:[],
      isSpinner:false,
      group_name:'',

    }
  },
  methods:{
    trySubmitFile(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.files_name.push(e.target.files[i])
      }
      var files = Array.from(e.target.files);
      console.log(typeof (files))
      files.forEach((file) => {
        this.myImages.push(file);
        var reader = new FileReader();
        var vm = this;
        reader.onload = function(e) {
          vm.imageUrl.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    createMarket(){
      this.isSpinner=true
      const form = new FormData();
      // form.append("logo", this.myImages[0]);
      form.append("name", this.group_name);
      this.$axiosDefault.post('group',form,
          {
            headers: {
              'Accept-Language': 'oz',
              'Authorization':  `Bearer  ${localStorage.getItem('_token')}`,
            }
          })
          .then(()=>{
            this.isSpinner=false
            this.$router.push('/groups')
          })
          .catch(()=>{
            this.$toast("Bu ma'lumotlar oldin ro'yxatdan otgan iltimos qayta tekshirib ko'ring", {
              timeout: 2000,
              type:"error"
            });
            this.isSpinner=false
          })

    }
  }
}
</script>
