<template>
  <div >
    <b-modal id="bv-modal-example" hide-footer>
      <template #modal-title>
        Manzil
      </template>
      <div class="d-block text-center">
        <h3 v-if="markenName.market_info[0]">{{markenName.market_info[0].address}}</h3>
      </div>
      <b-button class="btn-primary mt-3" block @click="$bvModal.hide('bv-modal-example')">Yopish</b-button>
    </b-modal>
    <div class="market_create">
      <div class="shops_nav">
        <div>
          <router-link to="/">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3159_2338)">
                <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_3159_2338">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Mijoz haqida
          </router-link>
        </div>
        <div class="market_create_price">
          <router-link :to="`/create/shop/${markenName.id}/create`" >

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9996 8.3273V15.6537" stroke="#191923" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15.6673 11.9905H8.33398" stroke="#191923" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
                    stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </router-link>
        </div>
      </div>
      <div class="market_body">
        <div class="market_center">
          <div class="market_logo" v-if="markenName.logo">
            <img :src="markenName.logo" alt="">
          </div>
          <div class="avatar_element" v-else>
            <div v-if="markenName.name">
              {{ markenName.name.charAt(0) }}
            </div>
          </div>
        </div>
        <div class="market_names">
          {{ markenName.name }}
        </div>
        <div class="market_action">
          <a href="#" class="call_phone">
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_725_4954)">
                  <path
                      d="M4.16667 3.33334H7.5L9.16667 7.5L7.08333 8.75C7.9758 10.5596 9.44039 12.0242 11.25 12.9167L12.5 10.8333L16.6667 12.5V15.8333C16.6667 16.2754 16.4911 16.6993 16.1785 17.0118C15.866 17.3244 15.442 17.5 15 17.5C11.7494 17.3025 8.68346 15.9221 6.38069 13.6193C4.07792 11.3165 2.69754 8.25062 2.5 5C2.5 4.55798 2.67559 4.13405 2.98816 3.82149C3.30072 3.50893 3.72464 3.33334 4.16667 3.33334"
                      stroke="#C70505" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_725_4954">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
              Qo’ng’iroq
            </div>
          </a>
          <a href="#" class="address_phone" id="show-btn" @click="$bvModal.show('bv-modal-example')">
            <div>

              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="location" opacity="1" clip-path="url(#clip0_3650_485)">
                  <path id="Vector" d="M8 9.33334C9.10457 9.33334 10 8.43791 10 7.33334C10 6.22877 9.10457 5.33334 8 5.33334C6.89543 5.33334 6 6.22877 6 7.33334C6 8.43791 6.89543 9.33334 8 9.33334Z" stroke="#003791" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M11.7707 11.1047L8.94202 13.9333C8.69201 14.1831 8.35307 14.3234 7.99968 14.3234C7.6463 14.3234 7.30736 14.1831 7.05735 13.9333L4.22802 11.1047C3.48217 10.3588 2.97424 9.40846 2.76848 8.3739C2.56271 7.33935 2.66834 6.26701 3.07202 5.29248C3.4757 4.31796 4.15928 3.48502 5.03634 2.899C5.9134 2.31298 6.94453 2.00019 7.99935 2.00019C9.05417 2.00019 10.0853 2.31298 10.9624 2.899C11.8394 3.48502 12.523 4.31796 12.9267 5.29248C13.3304 6.26701 13.436 7.33935 13.2302 8.3739C13.0245 9.40846 12.5165 10.3588 11.7707 11.1047V11.1047Z" stroke="#003791" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_3650_485">
                    <rect width="16" height="16" fill="#003791"/>
                  </clipPath>
                </defs>
              </svg>

              Manzil
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="price_returned">
      <div class="price_returned_text">
        Qarzdorlik:
      </div>
      <div class="price">
        {{markenName.market_balance}} so’m
      </div>
    </div>
    <div class="market_create">
      <div class="debt-info"
      >
        <div class="debt-client"
        >

          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" clip-path="url(#clip0_725_4982)">
              <path d="M17.5 5.83333H2.5" stroke="#003791" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M15 8.33333L17.5 5.83333L15 3.33333" stroke="#003791" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M5 16.6667L2.5 14.1667L5 11.6667" stroke="#003791" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M2.5 14.1667H17.5" stroke="#003791" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_725_4982">
                <rect width="20" height="20" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <span>Oldi berdilar</span>
        </div>
        <div>
          <a >
            <div class="info-debt" @click="active_add=!active_add">
              <span v-if="checkslist.meta">{{checkslist.meta.totalCount}}</span>
              <svg class="tog_icon " :class="{'transporents':active_add==true}" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L1 9" stroke="#191923" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div class="debt-check_f"   v-if="active_add">
        <div class="debt-check"
             v-for="(items, indexx) in checkslist.data"
             :key="indexx"
             @click="getBalance(items,indexx)"
             :class="{
             'active': items.active,
           'focus': items.payment_type_id == 2,
            }"
        >
          <div class="debt-date">
            <div class="debt-data">

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_795_5746)">
                  <path d="M9.99935 16.6667C13.221 16.6667 15.8327 14.055 15.8327 10.8333C15.8327 7.61167 13.221 5 9.99935 5C6.77769 5 4.16602 7.61167 4.16602 10.8333C4.16602 14.055 6.77769 16.6667 9.99935 16.6667Z" stroke="#191923" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10 8.33333V10.8333H11.6667" stroke="#191923" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5.83268 3.33333L3.54102 5" stroke="#191923" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14.166 3.33333L16.4577 5" stroke="#191923" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5746">
                    <rect width="20" height="20" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <span> {{ items.date }}</span>
            </div>
            <div class="debt-text">
            <span  @click="$router.push('/edit/shop/'+$route.params.id+'/edit/'+items.id)">
              <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img"
                   aria-label="pencil fill" aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                   class="bi-pencil-fill b-icon bi"><g><path
                  d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"></path></g></svg></span>
            </div>
          </div>
          <div class="debt-info-sum debt-info-sum2">
            <div class="debt-el">

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_795_5750)">
                  <path d="M12 5L12 19" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 5L6 11" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 5L18 11" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5750">
                    <rect width="24" height="24" fill="white" transform="translate(24) rotate(90)"/>
                  </clipPath>
                </defs>
              </svg>

              <span class="debt-text">oldi:</span>
            </div>
            <div class="debt-give">
              <span>{{ items.sum_order }}</span>
              <span> so'm</span>
            </div>
          </div>
          <div class="debt-info-sum">
            <div class="debt-el">

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_795_5751)">
                  <path d="M12 19L12 5" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 19L18 13" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 19L6 13" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5751">
                    <rect width="24" height="24" fill="white" transform="translate(0 24) rotate(-90)"/>
                  </clipPath>
                </defs>
              </svg>

              <span class="debt-text">berdi:</span>
            </div>
            <div class="debt-give">
              <span>{{ items.received_money }}</span>
              <span> so'm</span>
            </div>
          </div>
          <div class="debt-product">
            <div class="debt-text">Maxsulot turi:</div>
            <div class="debt-text-black">{{ items.type }}</div>
          </div>
          <div class="debt-product">
            <div class="debt-text">Izoh:</div>
            <div class="debt-text-black">{{ items.note }}</div>
          </div>
          <div class="debt-product debt-product2">
            <div class="debt-text">Diller ismi:</div>
            <div class="debt-text-black">{{ items.user }}</div>
          </div>
          <div class="debt-product debt-product2">
            <div class="debt-text">To'lov:</div>
            <div class="debt-text-black">{{ items.payment_type }}</div>
          </div>
          <div class="empty-price scliton" v-if="!balance"></div>
          <div class="sum-debt" v-if="balance">
            <span>{{ balance.current_balance }} </span>
            <span>so'm</span>
          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../../../components/Header/Footer";
export default {
  components: {Footer},
  data(){
    return{
      active: false,
      active_add:true,
      checkslist:[],
      item:20,
      markenName:'',
      balance:false,
      showCard:[],
      user:[],
      nextItem: 1,
    }
  },
  mounted() {

    this.$axiosDefault.get(`market/${this.$route.params.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer  ${localStorage.getItem('_token')}`,
          'Accept-Language': 'ru'
        }
      })
      .then((response) => {
        this.markenName=response.data.data
      });
    this.$axiosDefault.get(`market/${this.$route.params.id}/acts`,
            {
              headers: {
                "Content-Type": "application/json",
                'Accept-Language': 'ru',
                Authorization: "Bearer " + localStorage.getItem("_token"),
              },
            }
        )
        .then((response) => {
          this.checkslist = response.data;
          for (var i = 0; i < this.checkslist.data.length; i++) {
            this.checkslist.data[i].active = false;
          }
        });

        const listElms = window;

        listElms.addEventListener('scroll', ()=> {
          console.log("hello")
              if(this.nextItem<this.checkslist.meta.pageCount){
                this.nextItem=this.nextItem+1
                this.$axiosDefault.get(`market/${this.$route.params.id}/acts?page=${this.nextItem}`, {
                  headers: {
                    'Accept-Language': 'oz',
                    'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

                  }
                })
                    .then(res => {
                      for (var v=0;v<res.data.data.length;v++){
                        this.checkslist.data.push(res.data.data[v])
                      }

                    })
              }


    });

  },
  methods:{
    getBalance(items,indexx){
      this.balance = false;
      for (var i = 0; i < this.checkslist.data.length; i++) {
        this.checkslist.data[i].active = false;
      }
      this.checkslist.data[indexx].active = !this.checkslist.data[indexx].active;

      this.$axiosDefault.get(
          "market/" +
          this.$route.params.id + `/acts/${items.id}?include=current_balance`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("_token"),
            },
          }
      )

          .then((res) => {
            this.balance = res.data.data;
          });
    }
  }
}
</script>
<style>

#infinite-list {
  overflow: auto;
  height: 100vh;
  border-radius: 5px;
}
</style>
