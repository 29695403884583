<template>
  <div class="home padding_b">
    <div class="navbar">
      <div>
        <router-link to="/">
          <img src="../../assets/images/symbol.svg" alt="">
        </router-link>
      </div>
      <div class="text_groups">
        Guruhlar
      </div>
      <div class="button_top_navs">
        <router-link to="/create/group">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0001 7.3273V14.6537" stroke="#191923" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M14.6668 10.9905H7.3335" stroke="#191923" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z"
                  stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="main_cards groups">
      <div class="shop_card" v-for="(item,index) in group" :key="index">
        <router-link :to="`/data/group/${item.id}`">
          <div class="shop_card_left">
            <div class="shop_card_img" v-if="item.logo">
              <img :src="item.logo" alt="">
            </div>
            <div class="avatar_element" v-else>
              {{ item.name.charAt(0) }}
            </div>
            <div class="shop_card_text">
              <div class="shop_card_name">
                {{ item.name }}
              </div>
              <div class="shop_card_price">

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5" clip-path="url(#clip0_3125_3576)">
                    <path
                        d="M6.00016 7.33334L3.3335 10M3.3335 10L6.00016 12.6667M3.3335 10H10.6668C11.3741 10 12.0524 9.71905 12.5524 9.21896C13.0525 8.71886 13.3335 8.04058 13.3335 7.33334C13.3335 6.62609 13.0525 5.94782 12.5524 5.44772C12.0524 4.94762 11.3741 4.66667 10.6668 4.66667H10.0002"
                        stroke="#191923" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_3125_3576">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer},
  data() {
    return {
      group: [],
      isModal:false,
      show_title:{}
    }
  },
  mounted() {
    this.$axiosDefault.get('group?include=members,notifications', {
      headers: {
        'Accept-Language': 'oz',
        'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

      }
    })
        .then(res => {
          this.group = res.data.data
        })
        .catch(() => {
          localStorage.removeItem('_token')
          this.$router.push('/login')
        })
  }
}
</script>
