<template>
  <div class="home padding_b">
    <div class="navbar">
      <div>
        <router-link to="/">
          <img src="../../assets/images/symbol.svg" alt="">
        </router-link>
      </div>
      <div class="text_groups">
        Statistikalar
      </div>
      <div>

      </div>
    </div>
    <div class="statis_div">
      <router-link to="/today_stats">
        <div class="statis_father">
          <div class="main_statis">
            <div class="statis_circkli">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_795_5107)">
                  <path d="M9 21.75C8.85193 21.7493 8.70739 21.7047 8.5846 21.622C8.46181 21.5392 8.36626 21.422 8.31 21.285L4.7475 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H5.25C5.39807 11.2507 5.54261 11.2953 5.6654 11.378C5.78819 11.4608 5.88374 11.578 5.94 11.715L9 18.96L15.045 2.7375C15.0986 2.59395 15.195 2.47028 15.321 2.38312C15.447 2.29596 15.5968 2.24951 15.75 2.25C15.9047 2.25271 16.0547 2.30316 16.1796 2.39447C16.3044 2.48577 16.398 2.61344 16.4475 2.76L19.29 11.25H21.75C22.1642 11.25 22.5 11.5858 22.5 12C22.5 12.4142 22.1642 12.75 21.75 12.75H18.75C18.5928 12.7504 18.4394 12.7014 18.3115 12.6099C18.1837 12.5184 18.0878 12.389 18.0375 12.24L15.75 5.25L9.705 21.2625C9.65137 21.4061 9.55505 21.5297 9.429 21.6169C9.30296 21.704 9.15324 21.7505 9 21.75Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5107">
                    <rect width="24" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="statis_title">
              Bugungi ko’rsatkichlari
            </div>
          </div>
          <div>

            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="next" clip-path="url(#clip0_795_5092)">
                <path id="Vector" d="M6 4L10 8L6 12" stroke="#003791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_795_5092">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>
      </router-link>
      <router-link to="/courier">
        <div class="statis_father">
          <div class="main_statis">
            <div class="statis_circkli color_1">

              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="pie-chart 1">
                  <path id="Vector" d="M21.2104 15.89C20.5742 17.3945 19.5792 18.7202 18.3123 19.7513C17.0454 20.7824 15.5452 21.4874 13.9428 21.8048C12.3405 22.1221 10.6848 22.0421 9.12055 21.5718C7.55627 21.1015 6.13103 20.2551 4.96942 19.1067C3.80782 17.9582 2.94522 16.5428 2.45704 14.984C1.96886 13.4251 1.86996 11.7705 2.169 10.1646C2.46804 8.55878 3.1559 7.05063 4.17245 5.77203C5.189 4.49343 6.50329 3.48332 8.0004 2.83" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V12H22Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>

            </div>
            <div class="statis_title">
              Dillerlar bo'yicha ko’rsatkichlari
            </div>
          </div>
          <div>

            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="next" clip-path="url(#clip0_795_5092)">
                <path id="Vector" d="M6 4L10 8L6 12" stroke="#003791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_795_5092">
                  <rect width="16" height="16" fill="white"/>
                </clipPath>
              </defs>
            </svg>

          </div>
        </div>
      </router-link>
<!--      <router-link to="/">-->
<!--        <div class="statis_father">-->
<!--          <div class="main_statis">-->
<!--            <div class="statis_circkli color_2">-->

<!--              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <g id="bar-chart-2 1">-->
<!--                  <path id="Vector" d="M18 20V10" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path id="Vector_2" d="M12 20V4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path id="Vector_3" d="M6 20V14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </g>-->
<!--              </svg>-->

<!--            </div>-->
<!--            <div class="statis_title">-->
<!--              Bugungi ko’rsatkichlari-->
<!--            </div>-->
<!--          </div>-->
<!--          <div>-->

<!--            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <g id="next" clip-path="url(#clip0_795_5092)">-->
<!--                <path id="Vector" d="M6 4L10 8L6 12" stroke="#003791" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </g>-->
<!--              <defs>-->
<!--                <clipPath id="clip0_795_5092">-->
<!--                  <rect width="16" height="16" fill="white"/>-->
<!--                </clipPath>-->
<!--              </defs>-->
<!--            </svg>-->

<!--          </div>-->
<!--        </div>-->
<!--      </router-link>-->
    </div>
    <div class="bottom_stats">
      <div class="row">
        <div class="col-6">
          <div class="stats_b_text">
            Umumiy qarzdorlik
          </div>
          <div class="stats_b_price">
            {{ stats_data.sum }} so’m
          </div>
        </div>
        <div class="col-6">
          <div class="stats_b_text">
            Dillerlar soni
          </div>
          <div class="stats_b_price">
            {{stats_data.count}}
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer},
  data() {
    return {
      group: [],
      isModal:false,
      show_title:{},
      stats_data:[]
    }
  },
  mounted() {
    this.$axiosDefault.get('statistic/total', {
      headers: {
        'Accept-Language': 'oz',
        'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

      }
    })
        .then(res => {
          this.stats_data = res.data.data
        })
        .catch(() => {
          localStorage.removeItem('_token')
          this.$router.push('/login')
        })
  }
}
</script>
