<template>
  <div class="login_page">
    <div class="auth_fathers">
      <div class="auth_forms_title">
        <router-link to="/register">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9_1369)">
              <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_9_1369">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Parol yarating
        </router-link>
      </div>
      <div class="login_description">
        Dasturga kirish uchun yangi kod yarating
      </div>
      <form @submit.prevent="change_password()">
        <div class="form_input">

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3103_709)">
              <path d="M11.3333 7.33333H4.66665C3.93027 7.33333 3.33331 7.93029 3.33331 8.66667V12.6667C3.33331 13.403 3.93027 14 4.66665 14H11.3333C12.0697 14 12.6666 13.403 12.6666 12.6667V8.66667C12.6666 7.93029 12.0697 7.33333 11.3333 7.33333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.99998 11.3333C8.36817 11.3333 8.66665 11.0349 8.66665 10.6667C8.66665 10.2985 8.36817 10 7.99998 10C7.63179 10 7.33331 10.2985 7.33331 10.6667C7.33331 11.0349 7.63179 11.3333 7.99998 11.3333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.33331 7.33333V4.66667C5.33331 3.95942 5.61426 3.28115 6.11436 2.78105C6.61446 2.28095 7.29274 2 7.99998 2C8.70722 2 9.3855 2.28095 9.8856 2.78105C10.3857 3.28115 10.6666 3.95942 10.6666 4.66667V7.33333" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3103_709">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <input type="password" placeholder="Parol" v-model="password" id="myInput">
          <b-icon icon="eye" variant="white" v-if="show_icons" @click="myFunction()"></b-icon>
          <b-icon icon="eye-slash" variant="white" v-if="!show_icons" @click="myFunction()"></b-icon>
        </div>

        <div class="form_input">


          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3103_709)">
              <path d="M11.3333 7.33333H4.66665C3.93027 7.33333 3.33331 7.93029 3.33331 8.66667V12.6667C3.33331 13.403 3.93027 14 4.66665 14H11.3333C12.0697 14 12.6666 13.403 12.6666 12.6667V8.66667C12.6666 7.93029 12.0697 7.33333 11.3333 7.33333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.99998 11.3333C8.36817 11.3333 8.66665 11.0349 8.66665 10.6667C8.66665 10.2985 8.36817 10 7.99998 10C7.63179 10 7.33331 10.2985 7.33331 10.6667C7.33331 11.0349 7.63179 11.3333 7.99998 11.3333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5.33331 7.33333V4.66667C5.33331 3.95942 5.61426 3.28115 6.11436 2.78105C6.61446 2.28095 7.29274 2 7.99998 2C8.70722 2 9.3855 2.28095 9.8856 2.78105C10.3857 3.28115 10.6666 3.95942 10.6666 4.66667V7.33333" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3103_709">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <input type="password"  v-model="password_confirm" placeholder="Parolni tasdiqlang">
        </div>
        <div class="form_button">
          <button>
            <b-spinner v-if="isSpinner" variant="white" ></b-spinner>
            <span v-if="!isSpinner">Davom etish</span>
          </button>
        </div>
      </form>

    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      sho_icons:false,
      password_confirm:'',
      password:'',
      isSpinner:false,
      show_icons:true,
    }
  },
  methods:{
    change_password(){
      this.isSpinner=true
      this.$axiosDefault.post(`user/set-password?token=${this.$route.query.token}`,
          {
            "password" : this.password,
            "password_confirm" : this.password_confirm
          }
          )
          .then(res=>{
            console.log(res.data.data.token)
            localStorage.setItem('_token',res.data.data.token)
            this.$router.push('/')
            this.isSpinner=false
          })
          .catch(()=>{
            this.isSpinner=false
            this.$toast("Username yoki parol xato", {
              timeout: 2000,
              type:"error"
            });
          })
    },
    myFunction() {
      var x = document.getElementById("myInput");
      if (x.type === "password") {
        x.type = "text";
        this.show_icons=false
      }
      else {
        x.type = "password";
        this.show_icons=true
      }
    }
  }
}
</script>
