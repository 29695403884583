<template>
  <div class="market_create">
    <div class="creative_nav">
      <router-link to="/">

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3148_4721)">
            <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_3148_4721">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </router-link>
      <span> Mijozni tahrirlash</span>
    </div>
    <div class="form_main">
      <form @submit.prevent="createMarket()">
        <div class="create_form">
          <label>Mijoz username</label>
          <input type="text" v-model="name" required>
        </div>
        <div class="create_form">
          <label>Telefon raqam</label>

          <phone-mask-input
              v-model="phone_number"
              autoDetectCountry
              :showFlag="false"
              wrapperClass="wrraper-example"
              inputClass="input-example"
              flagClass="flag-example"
          />
        </div>
        <div class="create_form">
          <label>Manzil</label>
          <input type="text" v-model="address" required>
        </div>
        <div class="create_form my-4">
          <label>Oshkorlik</label>
          <div class="switch_text">Agar bu imkoniyat yoqilsa bu mijoz statistikasi barcha guruh a'zolariga ko'rinadi</div>
          <switches v-model="enabled" theme="bulma" color="blue"></switches>
        </div>



        <div class="create_button">
          <button :class="{'active':isSpinner}">
            <span v-if="!isSpinner">O'zgartirish</span>
            <b-spinner variant="white" v-if="isSpinner"></b-spinner>
          </button>
        </div>
      </form>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>

import Switches from 'vue-switches';
import PhoneMaskInput from  "vue-phone-mask-input";
import Footer from "../../components/Header/Footer";
export default {
  components:{
    Footer,
    PhoneMaskInput,
    Switches
  },
  data(){
    return{
      phone_number:'',
      address:'',
      name:'',
      isVisible:3,
      imageUrl:[],
      myImages:[],
      files_name:[],
      isSpinner:false
    }
  },
  mounted() {
    this.$axiosDefault.get(`market/${this.$route.params.id}`, {
      headers: {
        'Accept-Language': 'oz',
        'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

      }
    })
        .then(res => {
          this.market = res.data.data
          this.name=this.market.name
          this.address=this.market.market_info[0].address
          this.phone_number=this.market.market_info[0].phone
          this.isVisible=this.market.visible
        })
        .catch(() => {
          localStorage.removeItem('_token')
          this.$router.push('/login')
        })
  },
  methods:{
    trySubmitFile(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.files_name.push(e.target.files[i])
      }
      var files = Array.from(e.target.files);
      console.log(typeof (files))
      files.forEach((file) => {
        this.myImages.push(file);
        var reader = new FileReader();
        var vm = this;
        reader.onload = function(e) {
          vm.imageUrl.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    createMarket(){
      this.isSpinner=true
      const form = new FormData();
       form.append("visible", this.isVisible);
       form.append("phone", this.phone_number);
       form.append("address", this.address);
       form.append("name", this.name);
      this.$axiosDefault.put( `market/${this.$route.params.id}/update-market?include=logo`,form,
          {
            headers: {
              'Accept-Language': 'oz',
              'Authorization':  `Bearer  ${localStorage.getItem('_token')}`,
            }
          })
          .then(()=>{
            this.isSpinner=false
            this.$router.push('/')
          })
          .catch(()=>{
            this.$toast("Bu ma'lumotlar oldin ro'yxatdan otgan iltimos qayta tekshirib ko'ring", {
              timeout: 2000,
              type:"error"
            });
            this.isSpinner=false
          })

    }
  }
}
</script>
