<template>
  <div>
    <div class="market_create">
      <div class="shops_nav">
        <div>
          <router-link :to="`/create/shop/${$route.params.id}`">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3159_2338)">
                <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_3159_2338">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Ma’lumot qo’shish
          </router-link>
        </div>
      </div>
      <form @submit.prevent="createPro()">
        <div class="market_body">
          <div class="row">
            <div class="col-xl-12">
              <div class="form_inputs">
                <div>
                  <label>Oldi</label>
                </div>
                <input type="text" v-model="give"  @input="myFuncG(give)">
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form_inputs">
                <div>
                  <label>Berdi</label>
                </div>
                <input type="text" v-model="take"  @input="myFunc(take)">
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form_inputs" v-if="user">
                <div>
                  <label>Maxsulot turi </label>
                </div>
                <select
                    v-model="selecteds.id"
                >
                  <option
                      v-for="(item, index) in user.productTypes"
                      :key="index"
                      :value="item.id"
                      :selected="index == 0"
                  >{{ item.value }}</option>
                </select>
              </div>
            </div>
            <div class="col-xl-12">
              <label>To’lov turi</label>
              <div class="row " v-if="user">
                <div class="col-6 create_form2"
                     v-for="(items,i) in user.paymentTypes"
                     :key="i"
                >
                  <div class="input_check">
                    <input type="radio" name="visible" v-model="isVisible" :value="items.id" >
                    <label >{{items.value}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-12">
              <label>Izoh</label>
              <div class="row create_form2">
                <textarea placeholder="Izoh yozing ..." v-model="note"></textarea>
              </div>
            </div>
            <div class="col-xl-12 time_input">
              <div>
                <label>Oldi berdi vaqti</label>
              </div>
              <date-picker v-model="time2" type="datetime"></date-picker>
            </div>
          </div>
          <div>
            <button class="create_pro"
                    :class="{'active':isSpinner}">
              <span v-if="!isSpinner">qo’shish</span>
              <b-spinner variant="white" v-if="isSpinner"></b-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import Footer from "../../../components/Header/Footer";
export default {
  components: {Footer,DatePicker},
  data() {
    return {
      time1: new Date(),
      time2: new Date(),
      time3: null,
      isSpinner:false,
      isVisible:1,
      take:'',
      give:'',
      user:[],
      note:'',
      selecteds: {
        value: "",
        id: "",
      },
    };
    },
  mounted() {

    this.$axiosDefault.get('user/get-me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("_token"),
      }
    })
        .then((response) => {
          this.user = response.data.data;

        });
  },
  methods: {
    createPro(){
      this.isSpinner=true
      this.$axiosDefault.post(`market/${this.$route.params.id}/new-action`,
          {
            sum: Number(this.give.replace(/\s+/g, "")),
            received_money: Number(this.take.replace(/\s+/g, "")),
            type: this.selecteds.id,
            payment_type_id: this.isVisible,
            date: this.time2,
            note: this.note,
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('_token'),
              'Accept-Language': 'ru'
            }
          })
      .then(()=>{
        this.$router.push(`/create/shop/${this.$route.params.id}`)
        this.isSpinner=false
      })

          .catch(()=>{
            this.$toast("Bu ma'lumotlar oldin ro'yxatdan otgan iltimos qayta tekshirib ko'ring", {
              timeout: 2000,
              type:"error"
            });
            this.isSpinner=false
          })
    },
    changeLocation(event) {
      this.selecteds.id = event.target.value;
      console.log(event)
    },
    myFuncG(item) {
      item = item.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      this.give = item;
    },
    myFunc(item) {
      item = item.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      this.take = item;
    },
  },
  }
</script>
