<template>
  <div class="home padding_b">
    <div class="navbar">
      <div>
        <router-link to="/">
          <img src="../../assets/images/symbol.svg" alt="">
        </router-link>
      </div>
      <div class="text_groups">
        Diller ko’rsatkichlar
      </div>
      <div>

      </div>
    </div>
    <div class="stats_btn_f">
      <div class="stats_btn">
        <button @click="get_balance(item='today')">
          Bugun
        </button>
        <button @click="get_balance(item='week')">
          Oxirgi hafta
        </button>
        <button @click="get_balance(item='month')">
          Oxirgi oy
        </button>
        <button @click="get_balance(item='year')">
          Oxirgi yil
        </button>
      </div>
    </div>
    <div class="stats_today">
      <div class="row">
        <div class="col-6">
          <div class="today_price">
            <div>

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="up" clip-path="url(#clip0_795_5467)">
                  <path id="Vector" d="M10 4.16666L10 15.8333" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M10 4.16666L5 9.16666" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_3" d="M10 4.16666L15 9.16666" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5467">
                    <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
            <div class="today_title_price">
              {{stats_data.total_sum}} so’m
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="today_price">
            <div>


              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="down" clip-path="url(#clip0_795_5470)">
                  <path id="Vector" d="M10 15.8333L10 4.16668" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M10 15.8333L15 10.8333" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_3" d="M10 15.8333L5 10.8333" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5470">
                    <rect width="20" height="20" fill="white" transform="translate(0 20) rotate(-90)"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
            <div class="today_title_price main_color">
              {{stats_data.total_receive}} so’m
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_table_today" >
      <div class="main_today_table_head">
        <div class="table_head_text">
          №
        </div>
        <div class="table_head_text">
          Do’kon / vaqt
        </div>
        <div class="table_head_text">
          Oldi berdilar
        </div>
      </div>
      <div class="main_today_table_body" v-for="(item,index) in stats_data.data" :key="index">
        <div class="table_id">{{index+1}}</div>
        <div>
          <div class="main_table_title">{{item.name}}</div>
          <div class="main_table_time">
            {{new Date(item.time).toLocaleTimeString()}}
          </div>
        </div>
        <div>
          <div class="main_table_price">
            {{item.sum_order}} so’m
          </div>
          <div class="main_table_price reds">
            {{item.received_money}} so’m
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer},
  data() {
    return {
      group: [],
      isModal:false,
      show_title:{},
      stats_data:[]
    }
  },
  mounted() {
    this.$axiosDefault.get('statistic/latest-act?date=today', {
      headers: {
        'Accept-Language': 'oz',
        'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

      }
    })
        .then(res => {
          this.stats_data = res.data
        })
        .catch(() => {
          localStorage.removeItem('_token')
          this.$router.push('/login')
        })
  },
  methods:{

    get_balance(item){
      this.$axiosDefault.get(`statistic/latest-act?date=${item}`, {
        headers: {
          'Accept-Language': 'oz',
          'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

        }
      })
          .then(res => {
            this.stats_data = res.data
          })
          .catch(() => {
            localStorage.removeItem('_token')
            this.$router.push('/login')
          })
    }
  }
}
</script>
