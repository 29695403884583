<template>
  <div class="login_page">
    <div class="auth_fathers">
      <div class="auth_forms_title">
        <router-link to="/login">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9_1369)">
              <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_9_1369">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Ro’yxatdan o’tish
        </router-link>
      </div>
      <div class="auth_fathers_description">
        Dillerlikga
        ro’yxatdan o’tish
      </div>

      <form @submit.prevent="signUp()">
        <div class="form_input">

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3103_729)">
              <path d="M7.99998 7.33333C9.47274 7.33333 10.6666 6.13943 10.6666 4.66667C10.6666 3.19391 9.47274 2 7.99998 2C6.52722 2 5.33331 3.19391 5.33331 4.66667C5.33331 6.13943 6.52722 7.33333 7.99998 7.33333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3103_729">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <input type="text" placeholder="Username yarating" v-model="username">



        </div>
        <div class="form_input">

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3103_729)">
              <path d="M7.99998 7.33333C9.47274 7.33333 10.6666 6.13943 10.6666 4.66667C10.6666 3.19391 9.47274 2 7.99998 2C6.52722 2 5.33331 3.19391 5.33331 4.66667C5.33331 6.13943 6.52722 7.33333 7.99998 7.33333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3103_729">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <input type="text" placeholder="Email" v-model="email">



        </div>
        <div class="form_input">

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3103_729)">
              <path d="M7.99998 7.33333C9.47274 7.33333 10.6666 6.13943 10.6666 4.66667C10.6666 3.19391 9.47274 2 7.99998 2C6.52722 2 5.33331 3.19391 5.33331 4.66667C5.33331 6.13943 6.52722 7.33333 7.99998 7.33333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3103_729">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <input type="text" placeholder="Ism" v-model="first_name">



        </div>
        <div class="form_input">


          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6" clip-path="url(#clip0_3103_738)">
              <path d="M3.33333 2.66667H6L7.33333 6L5.66667 7C6.38064 8.44769 7.55231 9.61936 9 10.3333L10 8.66667L13.3333 10V12.6667C13.3333 13.0203 13.1929 13.3594 12.9428 13.6095C12.6928 13.8595 12.3536 14 12 14C9.39951 13.842 6.94677 12.7377 5.10455 10.8954C3.26234 9.05323 2.15803 6.60049 2 4C2 3.64638 2.14048 3.30724 2.39052 3.05719C2.64057 2.80714 2.97971 2.66667 3.33333 2.66667" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3103_738">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          <phone-mask-input
              v-model="phone_number"
              autoDetectCountry
              :showFlag="true"
              wrapperClass="wrraper-example"
              inputClass="input-example"
              flagClass="flag-example"
          />

        </div>

        <div class="form_button">
          <button>
            <b-spinner v-if="isSpinner" variant="white" ></b-spinner>
            <span v-if="!isSpinner">Ro’yxatdan o’tish</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import PhoneMaskInput from  "vue-phone-mask-input";
export default {
  components:{
    PhoneMaskInput
  },
  data(){
    return{
      sho_icons:false,
      username:'',
      first_name:'',
      phone_number:'',
      email:''
    }
  },
  methods:{
    signUp(){
      this.isSpinner=true
      this.$axiosDefault.post('user/register',
          {
            "username" : this.username,
            "phone":this.phone_number,
            "first_name" : this.first_name,
            "email" : this.email,

          }
          )
      .then(res=>{
        console.log(res)
        this.isSpinner=false
        this.$router.push(`/new_password?token=${res.data.data.token}`)
      })
      .catch(()=>{
        this.isSpinner=false
        this.$toast("Bu foydalanuvchi oldindan ro'yxatdan o'tgan", {
          timeout: 2000,
          type:"error"
        });
      })
    }
  }
}
</script>
