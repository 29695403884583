<template>
  <div>
    <div class="market_create">
      <div class="shops_nav">
        <div>
          <router-link :to="`/profile`">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_3159_2338)">
                <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_3159_2338">
                  <rect width="24" height="24" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            Maxsulot qo'shish
          </router-link>
        </div>
      </div>
      <form @submit.prevent="createPro()">
        <div class="market_body">
          <div class="row">
            <div class="col-xl-12">
              <div class="form_inputs">
                <div>
                  <label>Maxsulot nomi</label>
                </div>
                <input type="text" v-model="now_password"  >
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form_inputs">
                <div>
                  <label>Maxsulot narxi</label>
                </div>
                <input type="text" v-model="give"  @input="myFuncG(give)">
              </div>
            </div>
          </div>
          <div>
            <button class="create_pro"
                    :class="{'active':isSpinner}">
              <span v-if="!isSpinner">qo’shish</span>
              <b-spinner variant="white" v-if="isSpinner"></b-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../../components/Header/Footer";
export default {
  components: {Footer},
  data() {
    return {
      time1: new Date(),
      time2: new Date(),
      new_password:'',
      next_password:'',
      time3: null,
      isSpinner:false,
      isVisible:1,
      take:'',
      give:'',
      user:[],
      now_password:'',
      note:'',
      selecteds: {
        value: "",
        id: "",
      },
    };
  },
  mounted() {

    this.$axiosDefault.get('user/get-me', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("_token"),
      }
    })
        .then((response) => {
          this.user = response.data.data;

        });
  },
  methods: {
    createPro(){
      this.isSpinner=true
      this.$axiosDefault.post(`/profile/add-product`,
          {
            "price" :this.give,
            "confirm_password" : this.new_password,
            "name" :this.now_password
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('_token'),
              'Accept-Language': 'ru'
            }
          })
          .then(()=>{
            this.$router.push(`/products`)
            this.isSpinner=false
          })

          .catch(()=>{
            this.$toast("Ma'lumot kiritishda xatolik bor", {
              timeout: 2000,
              type:"error"
            });
            this.isSpinner=false
          })
    },
    changeLocation(event) {
      this.selecteds.id = event.target.value;
      console.log(event)
    },
    myFuncG(item) {
      item = item.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      this.give = item;
    },
    myFunc(item) {
      item = item.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      this.take = item;
    },
  },
}
</script>
