<template>
  <div class="market_create">
    <div class="creative_nav">
      <router-link to="/profile">

        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_3148_4721)">
            <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_3148_4721">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </router-link>
      <span>Maxsulotlar</span>
    </div>
    <div class="form_main">
      <div class="img_g">

        <div class="avatar_element" v-if="mains">
          {{ ("Mah").charAt(0) }}
        </div>
<!--        <input type="file" id="images">-->
      </div>
      <form @submit.prevent="createMarket()">

        <div class="create_people">
          <router-link to="/products/create" class="decoration">
            <div class="main_g">
              <div class="icon_g">

                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="add">
                    <g id="Plus">
                      <path id="Line_185" d="M11.9996 8.3273V15.6537" stroke="#003791" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Line_186" d="M15.6673 11.9905H8.33398" stroke="#003791" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"/>
                      <path id="Path" fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z"
                            stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="text_g">
                Maxsulot qo’shish
              </div>
              <div class="iconarrow_g">

                <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L5 5L1 9" stroke="#191923" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </div>
            </div>
          </router-link>
        </div>
        <div >

          <div  v-for="(item,i) in mains" :key="i">
            <div class="g_f">
              <router-link to="/create/people" class="decoration">
                <div class="main_g">
                  <div class="avatar_element" v-if="item.value">
                    {{ item.value.charAt(0) }}
                  </div>
                  <div class="text_g">
                    <span class="span_n"> {{item.value}}</span>
                    <span class="g_name"> {{item.value}}</span>
                  </div>
                </div>
              </router-link>

              <div class="iconarrow_g" @click="deleteGrop(item)">

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.5">
                    <path d="M13.3337 2.66675L2.66699 13.3334" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13.3337 13.3334L2.66699 2.66675" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                </svg>


              </div>

            </div>
          </div>
        </div>
      </form>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>

import Footer from "../../components/Header/Footer";
export default {
  components:{
    Footer,
  },
  data(){
    return{
      phone_number:'',
      address:'',
      name:'',
      isVisible:3,
      imageUrl:[],
      myImages:[],
      files_name:[],
      isSpinner:false,
      group_name:'',

      mains:[]
    }
  },
  mounted() {
    this.$axiosDefault.get(`/profile/product`,
        {
          headers: {
            'Accept-Language': 'oz',
            'Authorization':  `Bearer  ${localStorage.getItem('_token')}`,
          }
        })
        .then(res=>{
          this.mains=res.data.data
          console.log(this.mains)
        })
  },
  methods:{
    trySubmitFile(e) {
      for (let i = 0; i < e.target.files.length; i++) {
        this.files_name.push(e.target.files[i])
      }
      var files = Array.from(e.target.files);
      console.log(typeof (files))
      files.forEach((file) => {
        this.myImages.push(file);
        var reader = new FileReader();
        var vm = this;
        reader.onload = function(e) {
          vm.imageUrl.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    createMarket(){
      this.isSpinner=true
      const form = new FormData();
      // form.append("logo", this.myImages[0]);
      form.append("name", this.group_name);
      this.$axiosDefault.post('group',form,
          {
            headers: {
              'Accept-Language': 'oz',
              'Authorization':  `Bearer  ${localStorage.getItem('_token')}`,
            }
          })
          .then(()=>{
            this.isSpinner=false
            this.$router.push('/groups')
          })
          .catch(()=>{
            this.$toast("Bu ma'lumotlar oldin ro'yxatdan otgan iltimos qayta tekshirib ko'ring", {
              timeout: 2000,
              type:"error"
            });
            this.isSpinner=false
          })

    },
    deleteGrop(item){
      this.$axiosDefault.delete(`profile/${item.id}/remove-product`,
          {
            headers: {
              'Accept-Language': 'oz',
              'Authorization':  `Bearer  ${localStorage.getItem('_token')}`,
            }
          })
          .then(()=>{
            this.$axiosDefault.get(`/profile/product`,
                {
                  headers: {
                    'Accept-Language': 'oz',
                    'Authorization':  `Bearer  ${localStorage.getItem('_token')}`,
                  }
                })
                .then(res=>{
                  this.mains=res.data.data
                  console.log(this.mains)
                })
          })
    }
  }
}
</script>
