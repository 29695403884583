<template>
  <div class="home padding_b">
    <div class="stat_modals"  :class="{'active':isActive}">
      <div class="statis_card">
        <div>
          <div class="stast_title">
            <div class="avatar_element" v-if="main_item.name">
              {{ main_item.name.charAt(0) }}
            </div>
            <div>
              <div class="stats_text">
                {{ main_item.name }}
              </div>
              <div class="stats_date">
                {{new Date(main_item.time).toLocaleTimeString()}}
              </div>
            </div>
          </div>
          <div class="stats_date_f">
            <div class="stats_data">
              <div class="stats_title-date">

                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="user" clip-path="url(#clip0_795_5629)">
                    <path id="Vector" d="M7.9987 7.33333C9.47146 7.33333 10.6654 6.13943 10.6654 4.66667C10.6654 3.19391 9.47146 2 7.9987 2C6.52594 2 5.33203 3.19391 5.33203 4.66667C5.33203 6.13943 6.52594 7.33333 7.9987 7.33333Z" stroke="#003791" stroke-linecap="round" stroke-linejoin="round"/>
                    <path id="Vector_2" d="M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9.33333C10.0406 10 10.7189 10.281 11.219 10.781C11.719 11.2811 12 11.9594 12 12.6667V14" stroke="#003791" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_795_5629">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                Diller nomi:
              </div>
              <div class="stats_data_text">
                {{main_item.username}}
              </div>
            </div>
            <div class="stats_data">
              <div class="stats_title-date">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="phone" clip-path="url(#clip0_795_5630)">
                    <path id="Vector" d="M3.33333 2.66667H6L7.33333 6L5.66667 7C6.38064 8.44769 7.55231 9.61936 9 10.3333L10 8.66667L13.3333 10V12.6667C13.3333 13.0203 13.1929 13.3594 12.9428 13.6095C12.6928 13.8595 12.3536 14 12 14C9.39951 13.842 6.94677 12.7377 5.10455 10.8954C3.26234 9.05323 2.15803 6.60049 2 4C2 3.64638 2.14048 3.30724 2.39052 3.05719C2.64057 2.80714 2.97971 2.66667 3.33333 2.66667" stroke="#003791" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_795_5630">
                      <rect width="16" height="16" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                Tel raqam:
              </div>
              <div class="stats_data_text">
                {{main_item.phone}}
              </div>
            </div>
          </div>
          <div class="stats_contact">
            <div class="stats_phone">
              <a :href="`tel:${main_item.phone}`">
                Qo’ng’iroq
              </a>
            </div>
            <div class="stast_exet">
              <a href="#" @click="isActive=false">
                Ortga
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar">
      <div>
        <router-link to="/profile">

          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3148_4721)">
              <path d="M5 12H19" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 18" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 6" stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_3148_4721">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </router-link>
      </div>
      <div class="text_groups">
        Xabarnomalar
      </div>
      <div>
        <router-link to="/notification/create">
          <svg width="24" height="24" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.0001 7.3273V14.6537" stroke="#191923" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M14.6668 10.9905H7.3335" stroke="#191923" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.6857 1H6.31429C3.04762 1 1 3.31208 1 6.58516V15.4148C1 18.6879 3.0381 21 6.31429 21H15.6857C18.9619 21 21 18.6879 21 15.4148V6.58516C21 3.31208 18.9619 1 15.6857 1Z"
                  stroke="#191923" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </router-link>
      </div>
    </div>
    <div class="stats_btn_f">
      <div class="stats_btn">
        <VueSlickCarousel v-bind="settings">
          <div>
            <button @click="get_balance(item='today')">
              Bugun
            </button>
          </div>
          <div>
            <button @click="get_balance(item='week')">
              Oxirgi hafta
            </button>
          </div>
          <div>
            <button @click="get_balance(item='month')">
              Oxirgi oy
            </button>
          </div>
          <div>
            <button @click="get_balance(item='year')">
              Oxirgi yil
            </button>
          </div>
        </VueSlickCarousel>


      </div>
    </div>
    <div class="stats_today">
      <div class="row">
        <div class="col-6">
          <div class="today_price">
            <div>

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="up" clip-path="url(#clip0_795_5467)">
                  <path id="Vector" d="M10 4.16666L10 15.8333" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M10 4.16666L5 9.16666" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_3" d="M10 4.16666L15 9.16666" stroke="#C70505" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5467">
                    <rect width="20" height="20" fill="white" transform="translate(20) rotate(90)"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
            <div class="today_title_price">
              {{stats_data.total_sum}} so’m
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="today_price">
            <div>


              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="down" clip-path="url(#clip0_795_5470)">
                  <path id="Vector" d="M10 15.8333L10 4.16668" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_2" d="M10 15.8333L15 10.8333" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path id="Vector_3" d="M10 15.8333L5 10.8333" stroke="#003791" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_795_5470">
                    <rect width="20" height="20" fill="white" transform="translate(0 20) rotate(-90)"/>
                  </clipPath>
                </defs>
              </svg>

            </div>
            <div class="today_title_price main_color">
              {{stats_data.total_receive}} so’m
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_table_today" >
      <div class="main_today_table_head">
        <div class="table_head_text">
          №
        </div>
        <div class="table_head_text">
          Do’kon / vaqt
        </div>
        <div class="table_head_text">
          Oldi berdilar
        </div>
      </div>
      <div  @click="isActive=true;main_item=item" class="main_today_table_body" v-for="(item,index) in stats_data.data" :key="index">
        <div class="table_id">{{index+1}}</div>
        <div class="stats_m_text_f">
          <div class="main_table_title">{{item.name}}</div>
          <div class="main_table_time">
            {{new Date(item.time).toLocaleTimeString()}}
          </div>
        </div>
        <div class="main_prices">
          <div class="main_table_price">
            {{item.sum_order}} so’m
          </div>
          <div class="main_table_price reds">
            {{item.received_money}} so’m
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Footer from "../../components/Header/Footer";

export default {
  components: {Footer,VueSlickCarousel},
  data() {
    return {
      isActive:false,
      group: [],
      isModal:false,
      show_title:{},
      stats_data:[],
      settings:{
        "dots": false,
        "infinite":false,
        "speed": 500,
        "slidesToShow": 3.5,
        "slidesToScroll": 3.5,
        "initialSlide": 0,
        'arrows': false
      },
      main_item:{}
    }
  },
  mounted() {
    this.$axiosDefault.get('statistic/latest-act?date=today', {
      headers: {
        'Accept-Language': 'oz',
        'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

      }
    })
        .then(res => {
          this.stats_data = res.data
        })
        .catch(() => {
          localStorage.removeItem('_token')
          this.$router.push('/login')
        })
  },
  methods:{

    get_balance(item){
      this.$axiosDefault.get(`statistic/latest-act?date=${item}`, {
        headers: {
          'Accept-Language': 'oz',
          'Authorization': `Bearer  ${localStorage.getItem('_token')}`,

        }
      })
          .then(res => {
            this.stats_data = res.data
          })
          .catch(() => {
            localStorage.removeItem('_token')
            this.$router.push('/login')
          })
    }
  }
}
</script>
