<template>
  <div class="login_page">
    <div class="auth_fathers">
      <div class="auth_forms_title">
        <router-link to="/register">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_9_1369)">
              <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 18" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M5 12L11 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_9_1369">
                <rect width="24" height="24" fill="white"/>
              </clipPath>
            </defs>
          </svg>
          Ro’yxatdan o’tish
        </router-link>
      </div>

      <form @submit.prevent="checkCode()">
          <div class="form_title">
            Telefoningizga jo’natilgan parolni kiriting
          </div>
        <div class="form_code">
          <div>
            <input type="number" @input="showFunck(code1)" placeholder="-" v-model="code1">
          </div>
          <div>
            <input type="number" @input="showFunck(code2)" placeholder="-" v-model="code2">
          </div>
          <div>
            <input type="number" @input="showFunck(code3)" placeholder="-" v-model="code3">
          </div>
          <div>
            <input type="number" @input="showFunck(code4)" placeholder="-" v-model="code4">
          </div>
        </div>
        <div class="code_timer">

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_660_3359)">
              <path d="M7.99992 13.3333C10.5772 13.3333 12.6666 11.244 12.6666 8.66667C12.6666 6.08934 10.5772 4 7.99992 4C5.42259 4 3.33325 6.08934 3.33325 8.66667C3.33325 11.244 5.42259 13.3333 7.99992 13.3333Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 6.66667V8.66667H9.33333" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.66659 2.66667L2.83325 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.3333 2.66667L13.1666 4" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
              <clipPath id="clip0_660_3359">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>
      <span>
        0:{{secund}}
      </span>
          <div class="returin_code">
            Qayta jo’natish
          </div>
        </div>
        <div class="form_button">
          <button>
          <b-spinner v-if="isSpinner" variant="white" ></b-spinner>
          <span v-if="!isSpinner">Keyingisi</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      sho_icons:false,
      secund:59,
      code1:'',
      code2:'',
      code3:'',
      code4:'',
      isSpinner:false
    }
  },
  mounted() {
    const time=setInterval(()=>{
      if(this.secund>0){
        this.secund=this.secund-1
      }
      else clearInterval(time)
    },1000)

  },
  methods:{
    showFunck(item){
      if(item>10){
        this.code1='',
        this.code2='',
        this.code3='',
        this.code4=''
      }
      console.log(item)
    },
    checkCode(){
      this.isSpinner=true
      this.$axiosDefault.post(`user/confirm-phone?token=${this.$route.query.token}`,
          {
            "code" : (this.code1*100)+(this.code2*10)+(this.code3*1)+(this.code4)
          }
          )
          .then(res=>{
            this.isSpinner=false
            this.$router.push(`/new_password?token=${res.data.data.token}`)
          })
      .catch(()=>{
        this.isSpinner=false
      })
    }
  }
}
</script>
